import React from 'react';
import { Container, Col } from 'react-bootstrap';

function Treasury () {
    return (
        <Container className="pb-4">
            <h1>Treasury Services</h1>
            <Col>
                <p>NBE-NY is committed to helping our clients and counterparties in the MENA region and internationally to have efficient access to opportunities in the U.S. market through a set of tailored treasury products, services, and solutions allowing our customers to maximize profits and save time and expenses. These products and services include:</p>
                <ul>
                <li>USD Money Market Deposits; and</li>
                <li>Foreign Currencies Exchange Services.</li>
                </ul>
                <h2>Financial Institutions Services</h2>
                <p>National Bank of Egypt established its New York Branch in 2001 recognizing the importance of having an international foot print but also to compete in the world's financial center and markets.</p>
                <p>Being the leading and largest bank in Egypt, National Bank of Egypt's global participation in international banking is essential to the country, its financial system and institutions and its clientele.</p>
                <p>NBE-NY works closely with its Parent Bank and its global network and branches to develop and maintain business relationships with other financial institutions in Egypt, the MENA region, the Americas, and worldwide.</p>
            </Col>
        </Container>
    )
}

export default Treasury;