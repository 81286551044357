import React from 'react';
import { Container, Col } from 'react-bootstrap';

function About () {

    return (
        <Container className="pb-4">
            <h1>About Us</h1>
            <Col>
                <h2 style={{paddingTop:0}}>History</h2>
                <p>The National Bank of Egypt (NBE) is the oldest and the largest bank in Egypt with a global presence in Europe, Asia, Africa and North America.</p>
                <p>NBE was established on June 25, 1898 with a capital of £ 1 million. During the 1950s, NBE assumed the duties of a central bank, before The Central Bank of Egypt (CBE) was established in 1961.</p>
                <p>In 1960, after the nationalization, NBE became a commercial bank, and the in-charge bank of issuing and managing saving certificates on behalf of the government.</p>
            </Col>
            <hr />
            <Col>
                <h2>Who We Are</h2>
                <p>National Bank of Egypt – New York Branch (NBE-NY) was established in 2001, as a wholesale banking branch, licensed by New York State department of Financial Services (NYSDFS).</p>
                <p>The primary business of NBE-NY is developing and managing NBE Group’s global relationships with companies and financial institutions based in the Americas, and facilitating the trade and commercial business between Egypt, the Middle East and North Africa (MENA) region, and the United States.</p>
            </Col>
        </Container>
    )
}

export default About;