import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FaGlobeAsia, FaHandshake, FaDollarSign, FaCreditCard, FaArrowCircleRight } from "react-icons/fa";

import './home.css';

function Home () {
    return (
        <Container fluid>
            <Col>
                <Image
                    style={{backgroundSize : 'cover'}}
                    src="/banner-img.jpg"
                    className="d-inline-block align-top"
                    alt="National Bank of Egypt &#8211; New York Branch"
                    fluid
                />
                <div className="overlay">
                    <h2 style={{textAlign: 'center'}}>The National Bank of Egypt</h2>
                    <p style={{textAlign: 'center'}}>A History of Trust</p>
                </div>
            </Col>
            <Row>
                <Col md={8}>
                    <h2>History</h2>
                    <p>
                        The National Bank of Egypt (NBE) is the oldest and the largest bank in Egypt with a global presence in Europe, Asia, Africa and North America.
                        <br />
                        NBE was established on June 25, 1898, with a capital of £ 1 million. During the 1950s, NBE assumed the duties of a central bank, before The Central Bank of Egypt (CBE) was established in 1961.
                    </p>

                    <h2>Who We Are</h2>
                    <p>National Bank of Egypt – New York Branch (NBE-NY) was established in 2001, as a wholesale banking branch, licensed by New York State department of Financial Services (NYSDFS).</p>
                    <p>The primary business of NBE-NY is developing and managing NBE Group’s global relationships with companies and financial institutions based in the Americas, and facilitating the trade and commercial business between Egypt, the Middle East and North Africa (MENA) region, and the United States.</p>

                    <a href="about" className="border-button">
                        Learn More 
                        <FaArrowCircleRight className="fa fal"/>
                    </a>
                </Col>
                <Col md={4}>
                    <Image
                        src="/circles-new.png"
                        className="alignright"
                        alt="National Bank of Egypt &#8211; New York Branch"
                        fluid
                    />  
                </Col>
            </Row>
            <hr />
            <Col>
                <h2 style={{textAlign: 'center'}}>Products &amp; Services</h2>
                <Row className="justify-content-md-center">
                    <Col xs={6} md={3} className="widget">
                        <a href="trade-finance" className="widget-fa-link mt-3"><FaGlobeAsia className="fa"/></a>
                        <h3 className="noLink mt-4 mb-5"><a href="trade-finance" >Trade<br />Finance</a></h3>
                        <div className="widget-learn-more"><a href="trade-finance"  className="border-button">Learn More <FaArrowCircleRight className="fa fal"/></a></div>
                    </Col>
                    <Col xs={6} md={3} className="widget">
                        <a href="corporate" className="widget-fa-link mt-3"><FaHandshake className="fa"/></a>
                        <h3 className="noLink mt-4 mb-5"><a href="corporate" >Corporate<br /> Banking</a></h3>
                        <div className="widget-learn-more"><a href="corporate"  className="border-button">Learn More <FaArrowCircleRight className="fa fal"/></a></div>
                    </Col>
                    <Col xs={6} md={3} className="widget">
                        <a href="treasury" className="widget-fa-link mt-3"><FaDollarSign className="fa"/></a>
                        <h3 className="noLink mt-4 mb-5"><a href="treasury" >Treasury &#038; FI Services</a></h3>
                        <div className="widget-learn-more"><a href="treasury"  className="border-button">Learn More <FaArrowCircleRight className="fa fal"/></a></div>
                    </Col>
                    <Col xs={6} md={3} className="widget">
                        <a href="banking-services" className="widget-fa-link mt-3"><FaCreditCard className="fa"/></a>
                        <h3 className="noLink"><a href="banking-services" >Banking Services<br />(NBE-Egypt)</a></h3>
                        <div className="widget-learn-more"><a href="banking-services"  className="border-button">Learn More <FaArrowCircleRight className="fa fal"/></a></div>
                    </Col>
                </Row>
            </Col>
            <hr />
            <Col>
                <h2 style={{textAlign:'center'}}>National Bank of Egypt</h2>
                <h5 style={{textAlign:'center'}}>Direct Links</h5>
                <Row>
                    <Col xs={6} md={3}>
                        <Button variant="link" href="https://nbe.com.eg/NBE/E/#/EN/Home" className="noLink">
                            <Image
                                src="/Head-Office-min.png"
                                alt="Head Office"
                            />
                            <h4 className="text-uppercase">Head Office</h4> 
                        </Button>
                    </Col>
                    <Col xs={6} md={3}>
                        <Button variant="link" href="https://nbe.com.eg/NBE/E/#/EN/ATMBranch" className="noLink">
                            <Image
                                src="/NBE-Branches-in-Egypt-min.png"
                                alt="NBE Branches in Egypt"
                            />
                            <h4 className="text-uppercase">NBE Branches in Egypt</h4> 
                        </Button>
                    </Col>
                    <Col xs={6} md={3}>
                        <Button variant="link" href="https://nbe.com.eg/NBE/E/#/EN/ExchangeRatesAndCurrencyConverter" className="noLink">
                            <Image
                                src="/Currency-Exchange-Rates-min.png"
                                alt="Currency Exchange Rates"
                            />
                            <h4 className="text-uppercase">Currency Exchange Rates</h4> 
                        </Button>
                    </Col>
                    <Col xs={6} md={3}>
                        <Button variant="link" href="https://nbe.com.eg/NBE/E/#/EN/ProductCategory?inParams=%7B%22CategoryID%22%3A%22CertificatesID%22%7D" className="noLink">
                            <Image
                                src="/Deposits-Savings-Rates-min.png"
                                alt="Deposits &#038; Savings"
                            />
                            <h4 className="text-uppercase">Deposits &#038; Savings</h4> 
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Container>
    )
}

export default Home;