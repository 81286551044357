import React from 'react';
import { Container, Col } from 'react-bootstrap';

function Compliance () {
    return (
        <Container className="pb-4">
            <h1>Compliance &amp; Governance</h1>
            <Col>
                <p>National Bank of Egypt is committed to the highest standards of Anti-Money Laundering (AML) and Combating the Financing of Terrorism. The members of the Board and all employees are committed to adhere to these standards to protect NBE and its reputation from being misused for money laundering and/or terrorist financing or other illegal purposes.</p>
                <p>NBE-NY &nbsp;has its headquarters in Cairo – Egypt, which is supervised by the Central bank of Egypt.</p>
                <p>NBE will adhere to all applicable laws and regulations in all countries where it conducts business, or has business relationships to.</p>
                <p>NBE will examine its AML and CFT strategies and objectives on an ongoing basis and maintain an effective program for the Bank’s business that reflects the best practices for a diversified, international financial services provider.</p>
                <p>The program addresses all AML-related topics, especially Know Your Customer (KYC), ABC, Sanctions and Anti-Fraud. For all these topics NBE has implemented clear policies and procedures which must be complied with by all NBE staff.</p>
                <p className="noLink">For information about NBE Head office Compliance and Corporate Governance, <a href="http://www.nbe.com.eg/en/Default.aspx?AID=7&amp;CID=1#Title">click here</a></p>
            </Col>
        </Container>
    )
}

export default Compliance;