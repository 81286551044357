import React from 'react';
import { Container, Col } from 'react-bootstrap';

function Corporate () {
    return (
        <Container className="pb-4">
            <h1>Corporate Banking</h1>
            <Col>
                <p>A key objective of NBE-NY is to develop relationships with large, medium-sized and small companies that have business interests in the MENA region and in the Americas, which will benefit from National Bank of Egypt’s international presence.</p>
                <p>NBE-NY engages in all aspects of the commercial lending business, including syndicated lending facilities and lines of credit for financial institutions and sovereign-related entities.</p>
                <p>Corporate Banking Services include:</p>
                <ul>
                <li>Demand Deposit Accounts;</li>
                <li>Time Deposit Accounts;</li>
                <li>Credit Facilities; and</li>
                <li>Trade Finance Transactions.</li>
                </ul>
                <p>For more information please contact us at 212 326 8000</p>
            </Col>
        </Container>
    )
}

export default Corporate;