import React from 'react';
import { Container, Col, Table } from 'react-bootstrap';

function Banking () {

    return (
        <Container className="pb-4">
            <h1 style={{color: '#ff8f18'}}>Accounts in NBE-Egypt</h1>
            <Col>
                <p>NBE-NY is committed to facilitating customers of NEB-Egypt who reside in the Americas in opening and maintaining accounts (checking, savings and Certificates of Deposits) in USD and/or EGP at the National Bank of Egypt in Egypt.</p>
                <p className="noLink">For more information about accounts and Certificates of Deposit at National Bank of Egypt in Egypt. <a href="http://www.nbe.com.eg/en/Default.aspx?CID=5">please click here</a></p>
                <h2>Required Documents</h2>
                <ul>
                    <li>Complete Account Opening Application;</li>
                    <li>Signature Cards;</li>
                    <li>Letter of Instructions to the Branch in Egypt;</li>
                    <li>Power of Attorney 1;</li>
                    <li>Power of Attorney 2;</li>
                    <li>US Tax Form (W8);</li>
                    <li>US Tax Form (W9); and</li>
                    <li>Data Disclosure Form.</li>
                </ul>
                <p>NBE - NY accommodates customers by appointment only. To schedule an appointment and for all further inquiries, please call us at (212) 326 8142 or (212) 326 8104.</p>
                <p>Other Forms:</p>
                <ul>
                    <li>Customer Information Update Form;</li>
                    <li>Signature Cards;</li>
                    <li>Credit Card Application; and</li>
                    <li>Collateral Agreement for Credit Cards.</li>
                </ul>
            </Col>
            <h2 style={{color: '#ff8f18'}}>Remittance Services</h2>
            <Col>
                <p>NBE-NY is committed to facilitating remittance services for NBE- Egypt account holders. We assist NBE-Egypts customers residing in the Americas in executing money transfer transactions on their accounts at the National Bank of Egypt in Egypt.</p>
                <p className="noLink">For more information and to schedule an appointment with our Customer Service Representative please call us at <a href="tel:(212) 326 8142">(212) 326 8142</a> or <a href="tel:(212) 326 8104">(212) 326 8104</a>.</p>
                <h2>Required Documents:</h2>
                <h4>Remittances up to $4,999.99 :</h4>
                <p>Two Forms of Photo Identification: One with a Signature (i.e., Valid Passport, Resident Alien Card “Green Card,” Valid Visa, Driver's License, or Learners Permit with Photo), and an Identity Card “Foreign or Domestic,” and Work Permit with Photo; and<br />
                Contact Details (Address and Phone Number) for Sender and Recipient.</p>
                <h4>Remittances from $5,000.00 - $29,999.99 :</h4>
                <p>Two Forms of Photo Identification: One with Signature (i.e., Valid Passport, Resident Alien Card “Green Card,” Valid Visa, Driver's License, or Learners Permit with Photo), Identity Card “Foreign or Domestic,” and Work Permit with Photo;<br />
                Contact Details (Address and Phone Number) for Sender and Recipient;<br />
                Proof of Address (ID, Utility Bill, or Statement);<br />
                Proof of Funds (Pay Stub, W2, Bank Statement, or Tax Return); and<br />
                Purpose of Transaction.</p>
                <h4>for Remittances from $30,000.00 and Above (Further Requirements May Apply)</h4>

                <Table>
                    <thead>
                        <tr>
                        <th>Transfer Amount</th>
                        <th>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>Up to $1,999.99</td>
                    <td>$35.00</td>
                    </tr>
                    <tr>
                    <td>$2,000.00 - $4,999.99</td>
                    <td>$45.00</td>
                    </tr>
                    <tr>
                    <td>$5,000.00 - $9,999.99</td>
                    <td>$60.00</td>
                    </tr>
                    <tr>
                    <td>$10,000.00 &amp; Above</td>
                    <td>$70.00</td>
                    </tr>
                    </tbody>
                </Table>

                <p>NBE-NY reserves the right to change its fees and charges at any time without prior notice.</p>
                <h4>REQUIRED Documents:</h4>
                <ul>
                    <li>Funds Transfer Form; and</li>
                    <li>Remittance Transfer Dollar to Dollar Disclosure Form.</li>
                </ul>
                <h2>Important Information</h2>
                <ul>
                    <li>NBE-NY accepts only certified and Bank Checks. (no cash or money orders are accepted).</li>
                    <li>NBE-NY is committed to providing excellent customer service. Therefore, we can accommodate customers only by appointment.</li>
                    <li>NBE-NY does not have access to accounts held with any other National Bank of Egypt Branches in Egypt or elsewhere.</li>
                    <li><p className="noLink">For inquiries and assistance regarding accounts held with NBE-Egypt, please call 011 202 19623 NBE- Egypt's Customer Service Number, and visit<br />
                    <a href="http://www.nbe.com.eg">www.nbe.com.eg</a></p></li>
                </ul>
            </Col>

        </Container>
    )
}

export default Banking;