import React from 'react';
import { Container, Col } from 'react-bootstrap';

function Trade () {
    return (
        <Container className="pb-4">
            <h1>Trade Finance</h1>
            <Col>
                <p>Trade Finance is a core product for the National Bank of Egypt – New York Branch. Our trade finance activities promote and support the flow of trade between the United States, the Americas, the Middle East, and North Africa (MENA).</p>
                <p>We operate based on long-standing relationships maintained through the extensive network of NBE- Egypt's foreign and domestic branches as well as through regional and international banks involved in the trade finance business between MENA and the United States.</p>
                <p>We offer a wide range of Trade Finance Solutions, Products and Services to our customers, including:</p>
                <ul>
                <li>Issuance and advising of Standby Letters of Credit.</li>
                <li>Issuance of Import Documentary Letters of Credit.</li>
                <li>Advising and confirming Export Documentary Letters of Credit.</li>
                <li>Processing the Discount of Bankers’ Acceptances/Deferred Payments, under Confirmed Export Letters of Credit.</li>
                <li>Incoming and Outgoing Collections.</li>
                <li>Bank to Bank Reimbursements</li>
                <li>Structured Trade Finance Products.</li>
                </ul>
            </Col>
        </Container>
    )
}

export default Trade;