import React from 'react';
import Home from './home/home';
import { Route, Switch } from 'react-router-dom';
import Topbar from './shared/topbar';
import Footer from './shared/footer';
import About from './about';
import Trade from './trade';
import Compliance from './compliance';
import Corporate from './corporate';
import Treasury from './treasury';
import Banking from './banking';
import ExternalLinks from './externalLinks';
import Contact from './contact';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <>
    <Topbar/>
    <Switch>
      <Route exact path='/'>
        <Home/>
      </Route>
      <Route path='/about'>
        <About/>
      </Route>
      <Route path='/compliance'>
        <Compliance/>
      </Route>
      <Route path='/trade-finance'>
        <Trade/>
      </Route>
      <Route path='/corporate'>
        <Corporate/>
      </Route>
      <Route path='/treasury'>
        <Treasury/>
      </Route>
      <Route path='/banking-services'>
        <Banking/>
      </Route>
      <Route path='/usefulLinks'>
        <ExternalLinks/>
      </Route>
      <Route path='/contact'>
        <Contact/>
      </Route>
    </Switch>
    <Footer/>
    </>
  );
}

export default App;
