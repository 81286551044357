import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import './footer.css';

const MapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 40.7559666556735, lng: -73.97012740681225 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 40.7559666556735, lng: -73.97012740681225 }} />}
  </GoogleMap>
))

function Footer () {

    return (
        <Container style={{padding: '40px 0 38px', background: '#0c521c'}} fluid>
            <Row>
                <Col lg={3}>
                    <MapComponent
                        isMarkerShown
                        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC0ECZfKSTX-PFSVE_LZnE4rBSDWnfoYhQ&v=3.exp&libraries=geometry,drawing,places"
                        loadingElement={<div style={{ height: '100%' }} />}
                        containerElement={<div style={{width:'300px', height: '200px' }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                    />
                </Col>
                <Col lg={3}>
                    <h4 className="contact">Contact Information</h4>
                    <p className="contact">(212) 326-8000<br />
                        825 Third Ave. Fl 34, New York, NY 10022
                    </p>
                    <h4 className="contact">Hours of Operation</h4>
                    <p className="contact">Monday &#8211; Friday: 9:00 AM &#8211; 5:00 PM<br />
                        Saturday, Sunday: Closed
                    </p>
                </Col>
                <Col style={{textAlign:"right"}} lg={6}>
                    <p className="contact2" style={{fontWeight:"400px"}}><strong>National Bank of Egypt – New York Branch is not a Retail Bank.</strong><br />
                        <strong> Deposits at National Bank of Egypt – New York Branch are not FDIC insured.</strong>
                    </p>
                    <Button variant='link' href="/">
                        <Image 
                            src="/logo.jpg"
                            alt="National Bank of Egypt &#8211; New York Branch"
                        />
                    </Button>
                    <p className="contact">&copy; 2021 National Bank of Egypt - New York Branch. All rights reserved.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer;