import React from 'react';
import './topbar.css';
import { Navbar, Container, Nav, NavDropdown, Image} from 'react-bootstrap';
import { FaPhoneAlt } from "react-icons/fa";
import { useLocation } from 'react-router-dom';

function Topbar (){
    const location = useLocation();
    
    return (
        <Navbar bg="white" expand="lg">
            <Container expand="xl" className="mx-3">
                <Navbar.Brand href="/">
                    <Image 
                    src="/logo.jpg"
                    className="d-inline-block align-top"
                    alt="National Bank of Egypt &#8211; New York Branch, logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="borderless"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link className="text-uppercase" href="/" active={location.pathname === "/"}>Home</Nav.Link>
                        <NavDropdown className="text-uppercase" title="About Us" active={location.pathname === "/about" || location.pathname === "/compliance"}>
                            <NavDropdown.Item className="text-uppercase" href="/about" >History</NavDropdown.Item>
                            <NavDropdown.Item className="text-uppercase" href="/about" >Who We Are</NavDropdown.Item>
                            <NavDropdown.Item className="text-uppercase" href="/compliance" >Compliance &#038; Governance</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown 
                            className="text-uppercase" 
                            title="Products &#038; Services" 
                            active={
                                location.pathname === "/trade-finance" ||
                                location.pathname === "/corporate" ||
                                location.pathname === "/treasury" ||
                                location.pathname === "/banking-services"
                                }
                        >
                            <NavDropdown.Item className="text-uppercase" href="/trade-finance" >Trade Finance</NavDropdown.Item>
                            <NavDropdown.Item className="text-uppercase" href="/corporate" >Corporate Banking</NavDropdown.Item>
                            <NavDropdown.Item className="text-uppercase" href="/treasury" >Treasury &#038; F.I. Services</NavDropdown.Item>
                            <NavDropdown.Item className="text-uppercase" href="/banking-services" >Banking Services (NBE-Egypt)</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className="text-uppercase" href="/usefulLinks" active={location.pathname === "/usefulLinks"}>Useful Links</Nav.Link>
                        <Nav.Link className="text-uppercase" href="/contact" active={location.pathname === "/contact"}>Contact Us</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Brand className="justify-content-end right">
                    <FaPhoneAlt className="phone-icon mx-1"/>
                    <Navbar.Text className="phone-text">
                        <a href="tel:2123268000">212.326.8000</a>
                    </Navbar.Text>
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}

export default Topbar;