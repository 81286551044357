import React, { useState } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import './contact.css';

function Contact () {
    const products = ['Trade Finance','Corporate Banking','Treasury & FI Services', 'Customer Service', 'Others']
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [product, setProduct] = useState('');
    const [extra, setExtra] = useState('');

    const [requiredMsgName, setRequiredMsgName] = useState(false)
    const [requiredMsgEmail, setRequiredMsgEmail] = useState(false)
    const [requiredMsgExtra, setRequiredMsgExtra] = useState(false)

    const onNameInputChange = (event)  => {
        setRequiredMsgName(false)
        setName(event.target.value);
    }

    const onCompanyInputChange = (event)  => {
        setCompany(event.target.value);
    }

    const onPhoneInputChange = (event)  => {
        setPhone(event.target.value);
    }

    const onEmailInputChange = (event)  => {
        setRequiredMsgEmail(false)
        setEmail(event.target.value);
    }

    const onProductsInputChange = (event)  => {
        setProduct(event.target.value);
    }

    const onExtraInputChange = (event)  => {
        setRequiredMsgExtra(false)
        setExtra(event.target.value);
    }

    const handleSubmit = () => {
        var emptyValue = false

        if (name === "") {
            setRequiredMsgName(true)
            emptyValue = true
        }

        if (email === "") {
            setRequiredMsgEmail(true)
            emptyValue = true
        }

        if (extra === "") {
            setRequiredMsgExtra(true)
            emptyValue = true
        }

        if (!emptyValue) {
            alert(`${name},${company},${phone},${email},${product},${extra}`); 
        }
    }

    return (
        <Row className="row">
            <Col lg='4'>
                <h2>Contact Information</h2>
                <p><strong>Address:</strong><br/>
                825 Third Ave. Fl 34,<br/>
                New York, NY 10022</p>
                <p className="noLink"><strong>Phone Number: </strong><br/>
                <a href="tel:212 326 8000">212 326 8000</a></p>
                <p><strong>Swift BIC:</strong><br/>
                NBEGUS33XXX</p>
            </Col>
            <Col lg='8'>
                <Col style={{fontSize: '0.75em', textAlign: 'right'}}>
                <p>Fields marked with an <span style={{color: 'red'}}>*</span> are required</p>
                </Col>
                
                <Form>
                    <Row className="form-row">
                        <Col className="mb-3" lg={6}>
                            <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>Name <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" onChange={onNameInputChange.bind(this)} required isInvalid={requiredMsgName}/>
                            <Form.Control.Feedback type="invalid">
                                This is a required field.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group as={Col} controlId="formGridCompany">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter your company name" onChange={onCompanyInputChange.bind(this)}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Col className="mb-3" lg={6}>
                            <Form.Group as={Col} controlId="formGridPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="tel" placeholder="Enter your phone number" onChange={onPhoneInputChange.bind(this)}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control type="email" placeholder="Enter your email" onChange={onEmailInputChange.bind(this)} required isInvalid={requiredMsgEmail}/>
                            <Form.Control.Feedback type="invalid">
                                This is a required field.
                            </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="form-row">
                        <Form.Group as={Col} controlId="formGridProducts">
                        <Form.Label>Products &#038; Services <span style={{color: 'red'}}>*</span></Form.Label>
                        <Form.Select defaultValue={products[0]} onChange={onProductsInputChange.bind(this)}>
                            {products.map((product) => (
                                <option key={product}>{product}</option>
                            ))}
                        </Form.Select>
                        </Form.Group>
                    </Row>

                    <Row className="form-row">
                        <Form.Group controlId="formGridExtra">
                            <Form.Label>Additional Information <span style={{color: 'red'}}>*</span></Form.Label>
                            <Form.Control as="textarea" rows={12} onChange={onExtraInputChange.bind(this)} required isInvalid={requiredMsgExtra}/>
                            <Form.Control.Feedback type="invalid">
                                This is a required field.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group  className="mt-3">
                            <Button className="form-buttom" onClick={handleSubmit} >
                                Submit
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default Contact;