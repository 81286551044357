import React from 'react';
import { Container, Col } from 'react-bootstrap';

function ExternalLinks () {

    return (
        <Container className="pb-4">
            <h1>Useful Links</h1>
            <Col>
                <p class="noLink">
                    <a href="http://www.cbe.org.eg/en/Pages/default.aspx">The Central Bank of Egypt</a><br/>
                    <a href="http://www.miic.gov.eg/">Ministry of Investment and International Relations </a><br/>
                    <a href="http://www.investinegypt.gov.eg/english/Pages/default.aspx">Invest in Egypt</a><br/>
                    <a href="http://www.usegyptcouncil.org/">US Egypt Business Council</a><br/>
                    <a href="https://www.amcham.org.eg/">American Chamber of Commerce</a><br/>
                    <a href="http://www.egypt-nyc.com/homepage/en/">Consulate General of Egypt in New York</a><br/>
                    <a href="http://www.egyptembassy.net/">Embassy of Egypt, Washington D.C.</a><br/>
                    <a href="http://www.egypt.gov.eg/english/home.aspx">Egyptian Government Services</a><br/>
                    <a href="http://www.egypt.travel/en">Explore Egypt</a><br/>
                    <a href="http://www.nbe.com.eg/en/singlearticle.aspx?AID=2&amp;CID=31">Donate to Egypt Community</a><br/>
                    &nbsp;<br/>
                    &nbsp;<br/>
                    Disclaimer: We provide these links to external websites for your convenience. NBE-NY does not endorse and is not responsible for their content, links, privacy policies, or security policies.
                </p>
            </Col>
        </Container>
    )
}

export default ExternalLinks;